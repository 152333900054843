import { FC, useEffect, useState } from "react"
import { IFile, IFolder, INodeShare } from "../../api/api-client/api-types"
import { UserModuleType } from "../../contexts/application/constants"
import { useApplicationContext } from "../../contexts/application/context"
import { useToastContext } from "../../contexts/toasts"
import { useUserContext } from "../../contexts/users"
import { HoverType } from "../../utils/Types/shared"
import {
  OptionToSharePerms,
  postPermissionsandClose,
  RelationshipToContactType,
  TRadioOptions,
  userSharesToShareOptions,
} from "../../utils/shares"
import { UnsavedProgress } from "../UnsavedProgress/UnsavedProgress"
import { Button, BUTTON_ICON_POSITION } from "../atoms/Button"
import { PseudoLink } from "../atoms/PseudoLink/PseudoLink"
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalSubHeader,
} from "../modules/Modal"
import { FileFolderSharingPermissions } from "./SharingPermissions"
import "./SharingPermissions.css"

export interface SharingPermissionsNodeProps {
  folder?: IFolder
  file?: IFile
  onClose: () => void
  setIsCreateContactOpen?: () => void
  dispatch?: (value: any) => void
  shouldHideEditOption?: boolean
}
export const SharingPermissionsNode: FC<SharingPermissionsNodeProps> = ({
  folder,
  file,
  onClose,
  setIsCreateContactOpen,
  shouldHideEditOption,
}) => {
  const {
    applicationState: { folders, contactsShares: userSharesStatic, contacts },
    dispatch,
  } = useApplicationContext()
  const { dispatch: toastDispatch } = useToastContext()
  const {
    userState: { currentUser },
  } = useUserContext()

  const [isDisabled, setIsDisabled] = useState(true)
  const [saveClicked, setSaveClicked] = useState(false)
  const [userShares, setUserShares] = useState([] as INodeShare[])
  const [shareOptions, setShareOptions] = useState([] as TRadioOptions[])
  const [unsavedProgressOpen, setUnsavedProgressOpen] = useState(false)

  const node = file ? file : folder
  useEffect(() => {
    if (userSharesStatic) {
      setUserShares(userSharesStatic)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (contacts) {
      let filteredContacts = contacts
      if (
        currentUser?.modules?.includes(
          UserModuleType.HIDE_ORGANISATION_FROM_SHARING
        )
      ) {
        filteredContacts = contacts.filter(
          (contact) => contact.contactType !== "Company"
        )
      }

      setShareOptions(
        userSharesToShareOptions({
          userShares,
          contacts: filteredContacts,
          node: node!,
          folders,
        })
      )
    }
  }, [userShares, contacts, node, currentUser, folders])

  useEffect(() => {
    if (saveClicked) {
      setSaveClicked(false)
      if (node) {
        postPermissionsandClose({
          nodeId: node?.id,
          newShares: shareOptions.map((o) => {
            const newShare: INodeShare = {
              nodeId: o.nodeId,
              name: o.label!,
              contactType: RelationshipToContactType(
                contacts?.find((c) => c.contactId === o.contactId)
                  ?.relationship ?? "User"
              ),
              contactId: o.contactId,
              canRead: false,
              canEdit: false,
              sharedAfterDeath: false,
            }
            return OptionToSharePerms(o.option, newShare)
          }),
          dispatch,
          toastDispatch,
          onClose,
        })
      }
    }
    // TODO: we should not disable the eslint rule, we should fix the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveClicked])

  const getShouldShowEditOption = () => {
    if (shouldHideEditOption) {
      return false
    }

    if (folder) {
      return (
        folder.folderType !== "ConnectedFolder" &&
        folder.folderType !== "PlatformFolder"
      )
    }

    if (file && file.isEditable) {
      return true
    }

    return false
  }

  const showEditOption = getShouldShowEditOption()

  const closeModal = () => {
    if (unsavedProgressOpen) {
      setUnsavedProgressOpen(false)
      onClose()
    } else {
      if (!isDisabled) {
        setUnsavedProgressOpen(true)
      } else {
        onClose()
      }
    }
  }

  return (
    <div className="sharing-permissions">
      <Modal onClose={closeModal} name="Sharing permissions">
        {unsavedProgressOpen ? (
          <UnsavedProgress
            onExit={closeModal}
            onReturn={() => setUnsavedProgressOpen(false)}
            name="Sharing Permissions"
          />
        ) : (
          <>
            <ModalSubHeader icon="ThreeUsers">
              {`Edit individual ${file ? "file" : "folder"} permissions.`}
            </ModalSubHeader>
            <ModalHeader className="sharing-file-permissions-header">
              {node?.name}
            </ModalHeader>
            <ModalContent className="sharing-permissions">
              <FileFolderSharingPermissions
                sharingOptions={shareOptions}
                setSharingOptions={(newOptions: TRadioOptions[]) => {
                  setShareOptions(newOptions)
                  if (isDisabled) {
                    setIsDisabled(false)
                  }
                }}
                showEditOption={showEditOption}
                isFile={!!file}
              />

              <PseudoLink
                className={`new-contact ${HoverType.LIGHT}`}
                icon="Plus"
                iconPosition={BUTTON_ICON_POSITION.LEFT}
                onClick={() => {
                  if (setIsCreateContactOpen) {
                    setIsCreateContactOpen()
                  }
                }}
              >
                Add new contact
              </PseudoLink>
            </ModalContent>
            <ModalFooter className="sharing-permissions-modal-buttons">
              <Button
                className="mr-3"
                variant="tertiary"
                type="button"
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setSaveClicked(true)
                }}
                variant="primary"
                type="submit"
                isDisabled={isDisabled}
              >
                Save
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </div>
  )
}
